import axios from 'axios'
import { baseURL, timeout, successCode } from '@/settings'
import { ElMessage } from 'element-plus'
import { getToken, removeToken } from '@/utils/auth'
import store from '@/store'
import router from '@/router'

const instance = axios.create({
  baseURL,
  timeout
})

instance.interceptors.request.use(
  (config) => {
    if (store.getters.token) {
      config.headers.Authorization = getToken()
    }
    return config
  },
  (error) => {
    return Promise.reject(new Error(error))
  }
)

instance.interceptors.response.use(
  async ({ data: res }) => {
    // 身份过期
    if (res.code === 401001 || res.code === 401200 || res.code === 401201) {
      ElMessage.error('登录状态已过期，请重新登录！')
      removeToken()
      await store.commit('user/RESET_TOKEN')
      router.replace('/login')
      return false
    } else if (res.code !== successCode) {
      ElMessage.error(res.message)
      return false
    }
    return res
  },
  (error) => {
    console.log(error)
    // 弹窗提醒
    ElMessage.error('服务器连接超时，请联系网站管理员！')
    return Promise.reject(error)
  }
)
// get
export function get(options = {}) {
  return instance(options).catch((e) => e)
}

// post
export function post(options = {}) {
  return instance({ ...options, method: 'post' }).catch((e) => e)
}
