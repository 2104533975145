// 引入 Svg 组件

import SvgIcon from '@/components/SvgIcon/Index'

// 加载当前目录下所有的 svg 文件 ：参数1 目录 ，参数2 是否递归查找子目录，参数3 匹配svg 文件正则
const svgRequire = require.context('./svg', false, /\.svg$/)

// 循环 svg 文件数组，注册所有的 svg 组件
svgRequire.keys().forEach((item) => svgRequire(item))

// 暴露函数，接受 app ，注册组件

export default (app) => {
  app.component('svg-icon', SvgIcon)
}
