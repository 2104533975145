import { get } from '@/utils/request'
const loginApi = (data) => post({ url: '/auth/login', data })

/**
 * 获取文章详情
 * @returns
 */
export const getArticleDetailApi = (params) => get({ url: '/article/detail', params })

/**
 *
 * @param {*} params
 * @returns
 */
export const getAllArticleListApi = (params) => get({ url: '/article/allList', params })
