import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-d08ab9a6"), n = n(), _popScopeId(), n);
const _hoisted_1 = ["xlink:href"];
import { computed } from 'vue';
export default {
  __name: 'Index',
  props: {
    icon: {
      type: String,
      required: true
    },
    className: {
      type: String,
      default: ''
    },
    color: {
      type: String,
      default: '#000'
    }
  },
  setup(__props) {
    const props = __props;

    // 计算属性
    const iconName = computed(() => '#icon-' + props.icon);
    const svgClass = computed(() => {
      if (props.className) {
        return 'svg-icon ' + props.className;
      } else {
        return 'svg-icon';
      }
    });
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("svg", {
        class: _normalizeClass(svgClass.value),
        "aria-hidden": "true"
      }, [_createElementVNode("use", {
        "xlink:href": iconName.value
      }, null, 8, _hoisted_1)], 2);
    };
  }
};