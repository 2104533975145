import { createRouter, createWebHistory } from 'vue-router'
const routes = [
  {
    path: '/',
    name: '/',
    component: () => import(/* webpackChunkName: "about" */ '../layout'),
    redirect: '/main',
    children: [
      {
        path: '/main',
        name: 'Main',
        meta: { title: '主页', icon: 'huojian' },
        hidden: false,
        component: () => import(/* webpackChunkName: "about" */ '../views/main')
      },
      {
        path: '/subscribe',
        name: 'Subscribe',
        meta: { title: '订阅会员', icon: 'dingyue' },
        hidden: false,
        component: () => import(/* webpackChunkName: "about" */ '../views/subscribe')
      },
      {
        path: '/help',
        name: 'Help',
        meta: { title: '帮助支持', icon: 'bangzhu' },
        hidden: false,
        component: () => import(/* webpackChunkName: "about" */ '../views/help')
      }
    ]
  },
  {
    path: '/login',
    name: 'Login',
    meta: { title: '用户登录' },
    component: () => import(/* webpackChunkName: "about" */ '../views/auth/Login')
  },
  {
    path: '/register',
    name: 'Register',
    meta: { title: '用户注册' },
    component: () => import(/* webpackChunkName: "about" */ '../views/auth/Register')
  },
  {
    path: '/resetpwd',
    name: 'Resetpwd',
    meta: { title: '修改密码' },
    component: () => import(/* webpackChunkName: "about" */ '../views/auth/Resetpwd')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export function resetRouter() {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher // reset router
}

export default router
