module.exports = {
  title: '管理系统',

  appTitlePrefix: '小语AI',

  /**
   * @type {boolean} true | false
   * @description 顶部导航固定
   */
  fixedHeader: true,

  /**
   * @type {boolean} true | false
   * @description 侧边栏 logo
   */
  sidebarLogo: true,

  /**
   * @type {int}
   * @description axios 超时时间
   */
  timeOut: 5000,

  /**
   * @type {string}
   * @description axios 超请求基本地址
   */
  baseURL: 'https://v2ai.01qidian.com/home',
  // baseURL: '/home',

  /**
   * @type {string}
   * @description token 加密字符串
   */
  adminToken: 'HOME_TOKEN_PREFIX',

  /**
   * @type {array}
   * @description 路由白名单（不需要校验登录身份）
   */
  routerWhiteList: ['/login', '/register', '/resetpwd'],

  /**
   * @type {int}
   * @description 后端成功响应后返回的自定义状态码
   */
  successCode: 200001,

  /**
   * @type {string}
   * @description 跳转的 AI 页面地址
   */
  aiViteLink: 'https://wmcoming.com/'
}
