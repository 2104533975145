import { getToken, setToken, removeToken } from '@/utils/auth'
import { loginApi, getInfoApi, logoutApi } from '@/api'
import { successCode } from '@/settings'
import router from '@/router'
import { ElMessage } from 'element-plus'
const getDefaultState = () => ({
  token: getToken(),
  avatar: '',
  userInfo: {}
})

const state = getDefaultState()
const mutations = {
  SET_TOKEN(state, token) {
    state.token = token
  },
  RESET_TOKEN(state) {
    Object.assign(state, getDefaultState())
  },
  // SET_NICK: (state, nick) => {
  //   state.nick = nick
  // },
  // SET_USERNAME: (state, username) => {
  //   state.username = username
  // },
  SET_AVATAR: (state, avatar) => {
    state.avatar = avatar
  },
  SET_USERINFO: (state, userInfo) => {
    state.userInfo = userInfo
  }
  // SET_USERSTATE(state, userState) {
  //   state.userState = userState
  // },
  // SET_TIMELEFT(state, timeleft) {
  //   state.timeleft = timeleft
  // },
  // SET_EXIPRE_DATE(state, exipreDate) {
  //   state.exipreDate = exipreDate
  // }
}
const actions = {
  // 登录操作
  async login({ commit }, formData) {
    const result = await loginApi(formData)
    if (result.code === successCode) {
      ElMessage.success('登录成功！')
      commit('SET_TOKEN', result.data.token)
      setToken(result.data.token)
      router.replace('/')
    }
  },

  async getInfo({ commit }) {
    const result = await getInfoApi()
    if (result.code === successCode) {
      // commit('SET_NICK', result.data.nick)
      // commit('SET_USERNAME', result.data.username)
      // commit('SET_USERSTATE', result.data.user_status)
      // commit('SET_TIMELEFT', result.data.time_left)
      commit('SET_USERINFO', result.data)
      commit('SET_AVATAR', 'https://wpimg.wallstcn.com/f778738c-e4f8-4870-b634-56703b4acafe.gif')
    }
    return result
  },
  async logout({ commit }) {
    await logoutApi()
    removeToken()
    commit('RESET_TOKEN')
  }
}

export default {
  state,
  actions,
  mutations,
  namespaced: true
}
