import { post, get } from '@/utils/request'

/**
 * 管理员登录接口
 * @param {Object} data
 * @returns
 */
export const loginApi = (data) => post({ url: '/auth/login', data })

/**
 * 管理员退出登录接口
 * @returns
 */
export const logoutApi = () => get({ url: '/auth/logout' })

/**
 * 获取管理员信息接口
 * @returns
 */
export const getInfoApi = () => get({ url: '/auth/info' })

/**
 * 获取验证码接口
 * @param {*} parmas
 * @returns
 */
export const getCodeApi = (params) => get({ url: '/auth/getCode', params })

/**
 * 注册接口
 * @param {*} data
 * @returns
 */
export const registerApi = (data) => post({ url: '/auth/register', data })

/**
 * 修改密码接口
 * @param {*} data
 * @returns
 */
export const resetpwdApi = (data) => post({ url: '/auth/resetPassword', data })

/**
 * 更新密码接口
 * @param {*} data
 * @returns
 */
export const updatepwdApi = (data) => post({ url: '/auth/updatePassword', data })
